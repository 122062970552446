import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import styled, { StyleSheetManager } from 'styled-components';
import stylisRTLPlugin from 'stylis-plugin-rtl';

import { ImageCore, LOADING_OPTIONS, LinkWrapper, TextBlock } from '@components';
import { Box, Button, Grid } from '@core';
import { CheckoutNavBar, Footer } from '@containers';
import { formatLink } from '@shared/format-helpers';
import { getFetchedImage } from '@shared/image-helpers';
import { isLTR } from '@shared/translation-helpers';

const Wrapper = styled.div`
  direction: ltr;
`;

const FailurePage = ({ location, data }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const images = data.allFile.edges;

  return (
    <StyleSheetManager stylisPlugins={isLTR(language) ? [] : [stylisRTLPlugin]}>
      <Wrapper data-section-id="PaymentError">
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <CheckoutNavBar isLogoClickable />
        <Grid.Container minHeight="calc(100vh - 280px)">
          <Box display="flex" justifyContent="center">
            <ImageCore
              src={getFetchedImage(images, 'error-page/payment-error.png')}
              loading={LOADING_OPTIONS.eager}
              alt="error"
              maxHeight="50vh"
            />
          </Box>
          <Box mx={{ _: 24, md: 40 }} maxWidth={880}>
            <TextBlock
              title={t('paymentErrorPage:errorBlock.title')}
              description={t('paymentErrorPage:errorBlock.description')}
              textAlign="center"
            />
          </Box>
          <Box mt={32} mb={40} display="flex" justifyContent="center">
            <Box>
              <LinkWrapper link={location?.state?.backUrl || formatLink(t('navigation:paths.get'))}>
                <Button type="button" size="large" width={280}>
                  {t('paymentErrorPage:tryAgain')}
                </Button>
              </LinkWrapper>

              <Box py={24} height={58}>
                <LinkWrapper link={formatLink(t('navigation:paths.download'))}>
                  <Button type="button" height={58} variant="text" size="large" width={280}>
                    {t('common:actions.download')}
                  </Button>
                </LinkWrapper>
              </Box>
            </Box>
          </Box>
        </Grid.Container>
        <Footer compact="true" variant="light" />
      </Wrapper>
    </StyleSheetManager>
  );
};

export default FailurePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["common", "navigation", "paymentErrorPage"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allFile(
      filter: {
        relativePath: { in: ["error-page/payment-error.png"] }
        sourceInstanceName: { eq: "images" }
      }
    ) {
      edges {
        ...imageData
      }
    }
  }
`;
